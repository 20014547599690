import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnDestroy {

  public titulo: string;
  public tituloSubs$: Subscription;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.tituloSubs$ = this.getArgumentosRuta()
                              .subscribe( ({ titulo }) => {
                                  this.titulo = titulo;
                                  document.title = `TimbraTi - ${ titulo }`;
                              });
   }

  ngOnDestroy(): void {
    this.tituloSubs$.unsubscribe();
  }

  getArgumentosRuta() {

    return this._router.events
      .pipe(
        filter( event => event instanceof ActivationEnd ),
        filter( (event: ActivationEnd) => event.snapshot.firstChild === null  ),
        map( (event: ActivationEnd) => event.snapshot.data ),
      );
  }

  logout() {
    localStorage.clear();
    localStorage.removeItem('token');
    this._router.navigate(['/login']);
  }

}
