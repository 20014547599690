import { BotonInterface } from './../../../interfaces/boton.interface';
import { CompartirService } from './../../../services/compartir.service';
import { Component, OnInit, ViewChild, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.css']
})
export class TablaComponent implements OnInit, AfterViewInit {

  @Input() displayedColumns=[];
  @Input() columnas=[];
  @Input() dataSource=new MatTableDataSource();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @Output() editar: EventEmitter<number> = new EventEmitter();
  @Output() borrar: EventEmitter<number> = new EventEmitter();
  @Output() titulo: EventEmitter<number> = new EventEmitter();
  @Output() firmar: EventEmitter<number> = new EventEmitter();
  @Output() xml: EventEmitter<number> = new EventEmitter();
  @Output() status: EventEmitter<number> = new EventEmitter();
  @Output() cancelar: EventEmitter<number> = new EventEmitter();
  @Output() excel: EventEmitter<number> = new EventEmitter();
  @Output() desxml: EventEmitter<number> = new EventEmitter();


  constructor(
    private _compartir: CompartirService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  enviar(id: number, tipo: number){

    let boton: BotonInterface = {
      type: tipo,
      id: id
    };

    this._compartir.enviarBoton(boton);

    if(tipo === 1){
      this.editar.emit(id);
    }else if(tipo === 2){
      this.borrar.emit(id);
    }else if(tipo === 3){
      this.titulo.emit(id);
    }else if(tipo === 4){
      this.firmar.emit(id);
    }else if(tipo === 5){
      this.xml.emit(id);
    }else if(tipo === 6){
      this.status.emit(id);
    }else if(tipo === 7){
      this.cancelar.emit(id);
    }else if(tipo === 8){
      this.excel.emit(id);
    }else if(tipo === 9){
      this.desxml.emit(id);
    }

  }

}
