import { StatusComponent } from './status/status.component';
import { UsuarioNuevoComponent } from './usuario-nuevo/usuario-nuevo.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { FirmarNuevoComponent } from './firmar-nuevo/firmar-nuevo.component';
import { EditAlumnoComponent } from './edit-alumno/edit-alumno.component';
import { NuevoTituloComponent } from './nuevo-titulo/nuevo-titulo.component';
import { TituloComponent } from './titulo/titulo.component';
import { GenerarXmlComponent } from './generar-xml/generar-xml.component';
import { FirmarComponent } from './firmar/firmar.component';
import { AddAlumnoComponent } from './add-alumno/add-alumno.component';
import { AuthGuard } from './../guards/auth.guard';
import { AlumnosComponent } from './alumnos/alumnos.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagesComponent } from './pages.component';

const routes: Routes = [
    { 
        path: 'dashboard', 
        component: PagesComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard], 
        children: [
            { path: '', 
                component: DashboardComponent,
                data: { titulo: 'Dashboard' } 
            },
            { path: 'alumnos', 
                component: AlumnosComponent,
                data: { titulo: 'Alumnos' } 
            },
            { path: 'addAlumno', 
                component: AddAlumnoComponent,
                data: { titulo: 'Agregar Alumno' } 
            },
            { path: 'editAlumno', 
                component: EditAlumnoComponent,
                data: { titulo: 'Editar Alumno' } 
            },
            { path: 'titulo', 
                component: TituloComponent,
                data: { titulo: 'Crear Titulo' } 
            },
            { path: 'nuevo-titulo', 
                component: NuevoTituloComponent,
                data: { titulo: 'Nuevo Titulo' } 
            },
            { path: 'firmar', 
                component: FirmarComponent,
                data: { titulo: 'Firmar Titulo' } 
            },
            { path: 'firmar-nuevo', 
                component: FirmarNuevoComponent,
                data: { titulo: 'Autorizacion para Firma' } 
            },
            { path: 'generar', 
                component: GenerarXmlComponent,
                data: { titulo: 'Generar y Enviar XML' } 
            },
            { path: 'usuarios', 
                component: UsuariosComponent,
                data: { titulo: 'Usuarios' } 
            },
            { path: 'userCrud', 
                component: UsuarioNuevoComponent,
                data: { titulo: 'Usuarios' } 
            },
            { path: 'status', 
                component: StatusComponent,
                data: { titulo: 'Estatus de Titulo' } 
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {}
