import { GLOBAL } from './global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuxiliarService {

  url: string;
  token: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.url = GLOBAL.url;
   }
  
  carreras(): Observable<any>{

    /*let json=JSON.stringify(usuario);
    let params='json='+json;*/

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
    
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', this.token);

      return this._httpClient.post(this.url+'auxiliar/carreras', null, { headers: headers });
  }

  estudiosAntecedentes(): Observable<any>{

    /*let json=JSON.stringify(usuario);
    let params='json='+json;*/

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
    
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', this.token);

      return this._httpClient.post(this.url+'auxiliar/estudiosAntecedentes', null, { headers: headers });
  }

  estados(): Observable<any>{

    /*let json=JSON.stringify(usuario);
    let params='json='+json;*/

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
    
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', this.token);

      return this._httpClient.post(this.url+'auxiliar/estados', null, { headers: headers });
  }

  modalidad(): Observable<any>{

    /*let json=JSON.stringify(usuario);
    let params='json='+json;*/

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
    
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', this.token);

      return this._httpClient.post(this.url+'auxiliar/modalidad', null, { headers: headers });
  }

  fundamentos(): Observable<any>{

    /*let json=JSON.stringify(usuario);
    let params='json='+json;*/

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
    
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', this.token);

      return this._httpClient.post(this.url+'auxiliar/fundamentos', null, { headers: headers });
  }

  cargos(): Observable<any>{

    /*let json=JSON.stringify(usuario);
    let params='json='+json;*/

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
    
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', this.token);

      return this._httpClient.post(this.url+'auxiliar/cargos', null, { headers: headers });
  }

}
