<div class="col-lg-12">
    <div class="card">
        <div class="card-header">
            <h4>Firmar Titulo</h4>
        </div>
        <div class="card-body">
            <form [formGroup]="firmar" (ngSubmit)="guardar(firmar)" class="form-horizontal">
                <p class="text-muted m-b-15"><b>Instrucciones:</b> Debera Escribir su contraseña para poder firmar el documento.</p>
                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class=" form-control-label">Contraseña (*):</label>
                    </div>
                    <div class="col-12 col-md-7">
                        <input type="password" 
                        id="text-input" 
                        formControlName="passCert" 
                        name="text-input" 
                        placeholder="Contraseña de los Certificados del usuario" 
                        class="form-control">
                <small *ngIf="firmar.controls['passCert'].invalid && (firmar.controls['passCert'].dirty || firmar.controls['passCert'].touched)" class="form-text text-danger">Error en Contraseña</small>
                    </div>
                </div>

                <p class="text-muted m-b-15"><b>NOTA:</b> Los Campos con (*) son Requeridos.</p>
                <div class="col-12 text-right">
                    <button type="button" (click)="cancelar()" class="btn btn-danger">Cancelar</button>&nbsp;&nbsp;
                    <button type="submit" [disabled]="firmar.invalid" class="btn btn-success">Firmar</button>
                </div>
            </form>
        </div>
    </div>
</div>

