<app-spinner></app-spinner>
<app-sidebar></app-sidebar>
<div id="right-panel" class="right-panel">
    <app-header></app-header>
    <!-- Content -->
    <div class="content">
        <!-- Animated -->
        <div class="animated fadeIn">
            <router-outlet></router-outlet>
        </div>
        <div class="clearfix"></div>
        <app-footer></app-footer>
    </div>
</div>
