<div class="col-lg-12">
    <div class="card">
        <div class="card-header">
            <h4>Asignar Titulo</h4>
        </div>
        <div class="card-body">
            <form [formGroup]="frmTitulo" (ngSubmit)="guardar(frmTitulo)" class="form-horizontal">
                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Fecha de Expedicion (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="date"
                                id="text-input"
                                #fecha (keyup)="frmTitulo.patchValue({fecha: $event.target.value.toUpperCase()})"
                                formControlName="fecha"
                                name="text-input"
                                placeholder="Fecha de Expedicion"
                                class="form-control">
                        <small *ngIf="frmTitulo.controls['fecha'].invalid && (frmTitulo.controls['fecha'].dirty || frmTitulo.controls['fecha'].touched)" class="form-text text-danger">Error en Fecha de Expedicion</small>
                    </div>
                </div>




                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Modalidad (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <select name="idModalidad" id="idModalidad" formControlName="idModalidad" (change)="mostrarRequeridos($event.target.value)" class="form-control">
                            <option *ngFor="let modali of modalidades" [value]="modali.id" [selected]="modali.id === frmTitulo.value.idModalidad">{{ modali.modalidadTitulacion }}</option>
                        </select>
                    </div>
                </div>


                <div [hidden]="mostrarExamen" class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Fecha de Examen:</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="date"
                                id="text-input"
                                #fechaEx (keyup)="frmTitulo.patchValue({fechaExamen: $event.target.value.toUpperCase()})"
                                formControlName="fechaExamen"
                                name="text-input"
                                placeholder="Fecha de Examen"
                                class="form-control">
                        <small *ngIf="frmTitulo.controls['fechaExamen'].invalid && (frmTitulo.controls['fechaExamen'].dirty || frmTitulo.controls['fechaExamen'].touched)" class="form-text text-danger">Error en Fecha de Examen</small>
                    </div>
                </div>
                <div [hidden]="mostrarExcencion" class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Fecha de Excencion:</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="date"
                                id="text-input"
                                #fechaExencion (keyup)="frmTitulo.patchValue({fechaExencion: $event.target.value.toUpperCase()})"
                                formControlName="fechaExencion"
                                name="text-input"
                                placeholder="Fecha de Excencion"
                                class="form-control">
                        <small *ngIf="frmTitulo.controls['fechaExencion'].invalid && (frmTitulo.controls['fechaExencion'].dirty || frmTitulo.controls['fechaExencion'].touched)" class="form-text text-danger">Error en Fecha de Examen</small>
                    </div>
                </div>



                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Servicio Social (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <select name="cumplioServicio" id="cumplioServicio" formControlName="cumplioServicio" class="form-control">
                            <option *ngFor="let serv of siNo" [value]="serv.id" [selected]="serv.id === frmTitulo.value.cumplioServicio">{{ serv.valor }}</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Fundamento Legal (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <select name="idFundamento" id="idFundamento" formControlName="idFundamento" class="form-control">
                            <option *ngFor="let funda of fundamentos" [value]="funda.id" [selected]="funda.id === frmTitulo.value.idFundamento">{{ funda.fundamentoLegal }}</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Fecha de Inicio Carrera:</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="date"
                                id="text-input"
                                #fechaEx (keyup)="frmTitulo.patchValue({fechaInicioCarrera: $event.target.value.toUpperCase()})"
                                formControlName="fechaInicioCarrera"
                                name="text-input"
                                placeholder="Fecha de Inicio de Carrera"
                                class="form-control">
                        <small *ngIf="frmTitulo.controls['fechaInicioCarrera'].invalid && (frmTitulo.controls['fechaInicioCarrera'].dirty || frmTitulo.controls['fechaInicioCarrera'].touched)" class="form-text text-danger">Error en Fecha de Incio Carrera</small>
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Fecha de Fin Carrera (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="date"
                                id="text-input"
                                #fechaFinCarrera (keyup)="frmTitulo.patchValue({fechaFinCarrera: $event.target.value.toUpperCase()})"
                                formControlName="fechaFinCarrera"
                                name="text-input"
                                placeholder="Fecha fin de Carrera"
                                class="form-control">
                        <small *ngIf="frmTitulo.controls['fechaFinCarrera'].invalid && (frmTitulo.controls['fechaFinCarrera'].dirty || frmTitulo.controls['fechaFinCarrera'].touched)" class="form-text text-danger">Error en Fecha de fin de carrera</small>
                    </div>
                </div>

                <p class="text-muted m-b-15"><b>NOTA:</b> Los Campos con (*) son Requeridos.</p>
                <div class="col-12 text-right">
                    <button type="button" (click)="cancelar()" class="btn btn-danger">Cancelar</button>&nbsp;&nbsp;
                    <button type="submit" [disabled]="frmTitulo.invalid" class="btn btn-success">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>
            
