import { GLOBAL } from './global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  private url: string;
  private token: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.url = GLOBAL.url;
    this.token = this.getToken();
   }

  getToken(){
    let token=JSON.parse(localStorage.getItem("ses"));
    let token1=(token==null)?'undefined':token.token;

    return token1;
  }

  todos(frm){
    let json=JSON.stringify(frm);
    let params='json='+json;

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', this.token);
    
     return this._httpClient.post(this.url+'usuario', params, { headers: headers });
  }

  async nuevoUsrImg(
    cer: File,
    key: File,
    formulario
  ){
    try {
      // Creamos Variables
      const url = this.url+'usuario/add';

      // para enviar al back con peticion petch
      const formData = new FormData();
      formData.append('cer', cer);
      formData.append('key', key);
      formData.append('json', JSON.stringify(formulario));

      const respuesta = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization' : this.token
        },
        body: formData
      });

      const data = await respuesta.json();

      return data;

    } catch (error) {
      return false;
    }
  }

  nuevoUsr(frm){
    let json=JSON.stringify(frm);
    let params='json='+json;

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', this.token);
    
     return this._httpClient.post(this.url+'usuario/add', params, { headers: headers });
  }

}

