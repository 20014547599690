import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  private token: string;
  private url: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.url = GLOBAL.url;
    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
  }

  todos(frm): Observable<any>{
    let json=JSON.stringify(frm);
    let params='json='+json;

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'revisar/ver', params, { headers: headers });

  }

  verStatus(lote): Observable<any>{
    let loteE = {
      lote: lote
    };

    let json=JSON.stringify(loteE);
    let params='json='+json;

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'revisar/lote', params, { headers: headers });

  }

  descargar(lote): Observable<any>{
    let loteE = {
      lote: lote
    };

    let json=JSON.stringify(loteE);
    let params='json='+json;

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'revisar/descarga', params, { headers: headers });

  }

  cancelar(lote, motivo): Observable<any>{
    let loteE = {
      folio: lote,
      motivo: motivo
    };

    let json=JSON.stringify(loteE);
    let params='json='+json;

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'revisar/cancela', params, { headers: headers });

  }

  descargarXml(id): Observable<any>{
    let idAlumno = {
      idAlumno: id
    };

    let json=JSON.stringify(idAlumno);
    let params='json='+json;

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'xml/descargar', params, { headers: headers });

  }

}
