
<div class="table-stats order-table ov-h">
    <div class="table-responsive">
        <table mat-table class="table mat-elevation-z8" [dataSource]="dataSource" >

            <ng-container *ngFor="let columna of columnas" matColumnDef="{{ columna.fila }}">
                <th mat-header-cell *matHeaderCellDef fxFlex="{{ columna.tam }}"> {{ columna.titulo }} </th>
                <td mat-cell *matCellDef="let element" fxFlex="{{ columna.tam }}"> {{ element[columna.fila] }} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element" >
                    <div style="display: flex;">
                        <button class="btn btn-success btn-sm" (click)="enviar(element.id, 1)"><i class="fa fa-edit"></i></button>&nbsp;
                        <button class="btn btn-danger btn-sm" (click)="enviar(element.id, 2)"><i class="fa fa-trash-o"></i></button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="titulo">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element" >
                    <div style="display: flex;">
                        <button class="btn btn-primary btn-sm" (click)="enviar(element.id, 3)" title="Capturar Titulo"><i class="fa fa-list-alt"></i></button>&nbsp;
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="firmar">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element" >
                    <div style="display: flex;">
                        <button class="btn btn-warning btn-sm" (click)="enviar(element.id, 4)" title="Firmar Titulo"><i class="fa fa-pencil"></i></button>&nbsp;
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="xml">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element" >
                    <div style="display: flex;">
                        <button class="btn btn-secondary btn-sm" (click)="enviar(element.id, 5)"  title="Generar Xml"><i class="fa fa-file-text-o"></i></button>&nbsp;
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef="let element" >
                    <div style="display: flex;">
                        <button *ngIf="element.estatus == 1" class="btn btn-primary btn-sm" (click)="enviar(element.noLote, 6)" title="Ver Status"><i class="fa fa-asterisk"></i></button>&nbsp;
                        <button *ngIf="element.estatus == 1" class="btn btn-danger btn-sm" (click)="enviar(element.id, 7)" title="Cancelar"><i class="fa fa-ban"></i></button>&nbsp;
                        <button *ngIf="element.estatus == 2" class="btn btn-success btn-sm" (click)="enviar(element.noLote, 8)" title="Descargar Excel"><i class="fa fa-file-excel-o"></i></button>&nbsp;
                        <button *ngIf="element.estatus == 2" class="btn btn-primary btn-sm" (click)="enviar(element.id, 9)" title="Descargar Xmls"><i class="fa fa-file-code-o"></i></button>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons ></mat-paginator>
