import { NgxSpinnerService } from 'ngx-spinner';
import { catEstadosI } from './../../interfaces/catEstadosI';
import { fundamentosI } from './../../interfaces/fundamentosI';
import { TitulosService } from './../../services/titulos.service';
import { AuxiliarService } from './../../services/auxiliar.service';
import { catModalidadI } from './../../interfaces/catModalidadI';
import { VariablesService } from './../../services/variables.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-nuevo-titulo',
  templateUrl: './nuevo-titulo.component.html'
})
export class NuevoTituloComponent implements OnInit {

  idAlumno: number;
  public frmTitulo: FormGroup;
  public fecha=new Date();

  public mostrarExcencion = true;
  public mostrarExamen = true;

  public modalidades: Array<catModalidadI>;
  public fundamentos: Array<fundamentosI>;
  public estados: Array<catEstadosI>;

  public siNo: Array<{id: number, valor: string}>=[
    {id: 1, valor: 'SI'},
    {id: 0, valor: 'NO'}
  ];

  constructor(
    private _variablesService: VariablesService,
    private _router: Router,
    private _fb: FormBuilder,
    private _auxiliarService: AuxiliarService,
    private _titulosService: TitulosService,
    private _spinner: NgxSpinnerService
  ) {
    this.idAlumno=this._variablesService.idAlumno;
    if( typeof this.idAlumno=='undefined'){
      Swal.fire('Error', 'No se ha seleccionado Alumno', 'error');
      this._router.navigateByUrl('/');
    }else{
      this.frmTitulo = this._fb.group({
        idAlumno: [this.idAlumno, Validators.required],
        fecha: ['', [Validators.required]],
        idModalidad: [0, [Validators.required, Validators.pattern('[1-9]')]],
        fechaExamen: ['', null],
        fechaExencion: ['', null],
        fechaInicioCarrera: ['', Validators.required],
        fechaFinCarrera: ['', [Validators.required]],
        cumplioServicio: [0, [Validators.required]],
        idFundamento: ['', [Validators.required, Validators.pattern('[1-9]')]]
      });
    }
   }

  ngOnInit(): void {
    this._spinner.show();
    this.catModalidades();
    this.catFundamentos();
    this._spinner.hide();
  }

  async guardar(frm){
    if(frm.valid){
      this._spinner.show();
      await this._titulosService.guarda(frm.value).subscribe(
        response => {
          if(response.success ===1 ){
            Swal.fire('Exito', 'Titulo Guardado con Exito', 'success');
            frm.reset();
            this._router.navigateByUrl('dashboard/titulo');
          }
        },
        error => {
          console.log(error as any);
        }
      );
      this._spinner.hide();
    }
  }

  cancelar(){
    this._router.navigateByUrl('dashboard/titulo');
  }

  mostrarRequeridos(event){
    console.log(this.frmTitulo);
    const seleccionado = this.modalidades.filter( (modalidad) => {
      if(modalidad.id == event){
        return modalidad;
      }
    });

    const opcion: catModalidadI = seleccionado[0];

    if(opcion.idTipoFecha == 0){
      this.mostrarExcencion = true;
      this.mostrarExamen = false;

      // Validar Fecha Examen
      // this.frmTitulo.controls["fechaExamen"].clearValidators();
      this.frmTitulo.controls["fechaExencion"].clearValidators();
      this.frmTitulo.controls["fechaExencion"].setValue('');
      this.frmTitulo.controls['fechaExamen'].setValidators(Validators.required);

    }else{
      this.mostrarExcencion = false;
      this.mostrarExamen = true;

      // Validar Fecha Excencion
      // this.frmTitulo.controls["fechaExencion"].clearValidators();
      this.frmTitulo.controls["fechaExamen"].clearValidators();
      this.frmTitulo.controls["fechaExamen"].setValue('');
      this.frmTitulo.controls['fechaExencion'].setValidators(Validators.required);

    }

    this.frmTitulo.controls["fechaExamen"].updateValueAndValidity();
    this.frmTitulo.controls["fechaExencion"].updateValueAndValidity();

  }

  // Auxiliares
  async catModalidades(){
    await this._auxiliarService.modalidad().subscribe(
      response => {
        if(response.success === 1){
          this.modalidades=response.data;
          // idTipoFecha
        }else{
          Swal.fire('Error', 'No existen modalidades', 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

  async catFundamentos(){
    await this._auxiliarService.fundamentos().subscribe(
      response => {
        if(response.success === 1){
          this.fundamentos=response.data;
        }else{
          Swal.fire('Error', 'No existen Fundamentos', 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

}


