import { BotonInterface } from './../../interfaces/boton.interface';
import { CompartirService } from './../../services/compartir.service';
import { UsuariosService } from './../../services/usuarios.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styles: [
  ]
})
export class UsuariosComponent implements OnInit {

  public cabecera = [];
  origen = new MatTableDataSource();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  datos = [];
  mostrarTabla = true;

  public formBuscar: FormGroup;

  public opciones =[
    { id: 0, valor: 'Todos' },
    { id: 1, valor: 'Por Correo' },
    { id: 2, valor: 'Apellidos' }
  ];


  constructor(
    private _fb: FormBuilder,
    private _usuariosService: UsuariosService,
    private _router: Router,
    private _compartir: CompartirService
  ) { }

  ngOnInit(): void {
    this.formBuscar = this._fb.group({
      idTipo: [0, [Validators.required]],
      email: ['', [Validators.min(3), Validators.email]],
      primerApellido: ['', [Validators.min(3)]],
      segundoApellido: ['', [Validators.min(3)]]
    });

    this.todos(this.formBuscar);

    this._compartir.idBotonObservable.subscribe(
      (boton: BotonInterface) => {
        if(boton.type === 1){
          this.editar(boton.id);
        }
        if(boton.type === 2){
          this.borrar(boton.id);
        }
      });
  }

  nuevo(event){
    console.log('Nuevo: ', event);
  }

  editar(event){
    console.log('Editar: ', event);
  }

  borrar(event){
    console.log('Borrar: ', event);
  }

  agregar(){
    this._router.navigateByUrl('dashboard/userCrud');

  }

  todos(frm){
    if(frm.valid){
      this._usuariosService.todos(frm.value).subscribe(
        (response: any )=> {
          if(response.success === 1){
            this.cabecera = [];
            this.datos =[
              { titulo: 'id', fila: 'id', tam: '100px' },
              { titulo: 'email', fila: 'email', tam: '200px' },
              { titulo: 'Primer Apellido', fila: 'primerApellido', tam: '150px' },
              { titulo: 'Segundo Apellido', fila: 'segundoApellido', tam: '150px' },
              { titulo: 'Nombre', fila: 'nombre', tam: '200px' },
              { titulo: 'Puede Firmar', fila: 'puedeFirmar', tam: '150px' },
            ];

            for(let col in this.datos){
              this.cabecera.push(this.datos[col].fila);
            }

            this.cabecera.push('actions');
            this.origen.data = response.data;
            this.mostrarTabla = false;

          }else{
            Swal.fire('Error', 'No existen Datos', 'error');
          }
        },
        error => {
          console.log(error as any);
        }
      );
    }else{
      Swal.fire('Error', 'No existen usuarios', 'error');
    }
  }

}
