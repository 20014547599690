<div class="col-lg-12">
    <div class="card">
        <div class="card-header">
            <h4>Alta de Usuario</h4>
        </div>
        <div class="card-body">
            <form [formGroup]="formUsuario" (ngSubmit)="guardar(formUsuario)" class="form-horizontal">
                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Email (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="email"
                                id="text-input"
                                formControlName="email"
                                name="text-input"
                                placeholder="Email"
                                class="form-control">
                        <small *ngIf="formUsuario.controls['email'].invalid && (formUsuario.controls['email'].dirty || formUsuario.controls['email'].touched)" class="form-text text-danger">Error en Email</small>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Primer Apellido (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="text"
                                id="text-input"
                                #primerApellido (keyup)="formUsuario.patchValue({primerApellido: $event.target.value.toUpperCase()})"
                                formControlName="primerApellido"
                                name="text-input"
                                placeholder="Primer Apellido"
                                class="form-control">
                        <small *ngIf="formUsuario.controls['primerApellido'].invalid && (formUsuario.controls['primerApellido'].dirty || formUsuario.controls['primerApellido'].touched)" class="form-text text-danger">Error en Primer Apellido</small>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Segundo Apellido:</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="text"
                                id="text-input"
                                #segundoApellido (keyup)="formUsuario.patchValue({segundoApellido: $event.target.value.toUpperCase()})"
                                formControlName="segundoApellido"
                                name="text-input"
                                placeholder="Segundo Apellido"
                                class="form-control">
                        <small *ngIf="formUsuario.controls['segundoApellido'].invalid && (formUsuario.controls['segundoApellido'].dirty || formUsuario.controls['segundoApellido'].touched)" class="form-text text-danger">Error en Segundo Apellido</small>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Nombre (s) (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="text"
                                id="text-input"
                                #nombre (keyup)="formUsuario.patchValue({nombre: $event.target.value.toUpperCase()})"
                                formControlName="nombre"
                                name="text-input"
                                placeholder="Nombre (s)"
                                class="form-control">
                        <small *ngIf="formUsuario.controls['nombre'].invalid && (formUsuario.controls['nombre'].dirty || formUsuario.controls['nombre'].touched)" class="form-text text-danger">Error en Nombre</small>
                    </div>
                </div>
                <br>
                <div class="row form-group">
                    <div class="col-12 col-md-9">
                        <div class="form-check form-check-flat form-check-primary">
                            <label class="form-check-label">
                              <input type="checkbox" formControlName="firmar" (change)="cambio()" class="form-check-input">
                              Puede Firmar
                            <i class="input-helper"></i></label>
                        </div>
                    </div>
                </div>
                <br>

                <div *ngIf="formUsuario.value.firmar">

                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Abrebiatura (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="text"
                                id="text-input"
                                #abrebiatura (keyup)="formUsuario.patchValue({abrebiatura: $event.target.value.toUpperCase()})"
                                formControlName="abrebiatura"
                                name="text-input"
                                placeholder="Abrebiatura"
                                class="form-control">
                        <small *ngIf="formUsuario.controls['abrebiatura'].invalid && (formUsuario.controls['abrebiatura'].dirty || formUsuario.controls['abrebiatura'].touched)" class="form-text text-danger">Error en Abrebiatura</small>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Curp (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="text"
                                id="text-input"
                                #abrebiatura (keyup)="formUsuario.patchValue({curp: $event.target.value.toUpperCase()})"
                                formControlName="curp"
                                name="text-input"
                                placeholder="CURP"
                                class="form-control">
                        <small *ngIf="formUsuario.controls['curp'].invalid && (formUsuario.controls['curp'].dirty || formUsuario.controls['curp'].touched)" class="form-text text-danger">Error en CURP</small>
                    </div>
                </div>
                
                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Cargo (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <select name="idCargo" id="idCargo" formControlName="idCargo" class="form-control">
                            <option *ngFor="let carg of cargosA" [value]="carg.id" [selected]="carg.id === formUsuario.value.idCargo">{{ carg.cargo }}</option>
                        </select>
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Certificado (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="file"
                                id="cer"
                                name="cer"
                                placeholder="Archivo Cer"
                                accept="application/x-x509-ca-cert, .cer"
                                (change)="archivoCer($event.target.files[0])"
                                class="form-control">
                    </div>
                </div>

                <div class="row form-group">
                    <div class="col col-md-3">
                        <label for="text-input" class="form-control-label">Llave (*):</label>
                    </div>
                    <div class="col-12 col-md-9">
                        <input type="file"
                                id="key"
                                name="key"
                                placeholder="Archivo Key"
                                accept=".key"
                                (change)="archivoKey($event.target.files[0])"
                                class="form-control">
                    </div>
                </div>

                </div>

                <p class="text-muted m-b-15"><b>NOTA:</b> Los Campos con (*) son Requeridos.</p>
                <div class="col-12 text-right">
                    <button type="button" (click)="cancelar()" class="btn btn-danger">Cancelar</button>&nbsp;&nbsp;
                    <button type="submit" [disabled]="formUsuario.invalid" class="btn btn-success">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>
