import { NgxSpinnerService } from 'ngx-spinner';
import { antecedentesI } from './../../interfaces/antecedentesI';
import { alumnoI } from './../../interfaces/alumnoI';
import { VariablesService } from 'src/app/services/variables.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { catCarrerasI } from 'src/app/interfaces/catCarrerasI';
import { estudiosAntecedentesI } from 'src/app/interfaces/estudiosAntecedentesI';
import { catEstadosI } from 'src/app/interfaces/catEstadosI';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { AlumnosService } from 'src/app/services/alumnos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-alumno',
  templateUrl: './edit-alumno.component.html'
})
export class EditAlumnoComponent implements OnInit {

  public alumno: FormGroup;
  public carreras: Array<catCarrerasI>;
  public tipoEstudiosAntecedentes: Array<estudiosAntecedentesI>;
  public estados: Array<catEstadosI>;
  public generales: alumnoI;
  public antecendetes: antecedentesI;

  private idAlumno: number;

  public fecha: Date;

  constructor(
    private _fb: FormBuilder,
    private _auxiliarService: AuxiliarService,
    private _alumnoService: AlumnosService,
    private _variablesService: VariablesService,
    private _router: Router,
    private _spinner: NgxSpinnerService
  ) {
    this.idAlumno = this._variablesService.idAlumno;
    if( typeof this.idAlumno == 'undefined'){
      Swal.fire('Error', 'No se ha seleccionado Alumno', 'error');
      this._router.navigateByUrl('/');
    }
    this.traer();
  }

  ngOnInit(): void {
    this.traeCarreras();
    this.traeEstudiosAntecedentes();
    this.traeEstados();


    // llenamos el Formulario
    this.alumno = this._fb.group({
      idAlumno: [this.idAlumno, Validators.required],
      matricula: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]],
      curp: ['', [Validators.required, Validators.pattern('^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$')]],
      primerApellido: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(500)]],
      segundoApellido: ['', Validators.maxLength(500)],
      nombre: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(500)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(500)]],
      carrera: [0, [Validators.required, Validators.pattern('[1-9]')]],
      procedencia: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
      tipoEstudio: [0, [Validators.required, Validators.pattern('[1-9]')]],
      estado: [0, [Validators.required]],
      inicio: ['', null],
      terminacion: ['', [Validators.required]]
    });
  }



  async modificar(formulario){
    if(formulario.valid){
      this._spinner.show();
      this.alumno.controls.matricula.setValue(this.generales.matricula);
      await this._alumnoService.modificar(formulario.value).subscribe(
        response => {
          if(response.success === 1){
            Swal.fire('Exito', 'Alumno Modificado con Exito', 'success');
            formulario.reset();
            this._router.navigateByUrl('dashboard/alumnos');
          }else{
            Swal.fire('Error', response.error, 'error');
          }
        },
        error => {
          console.log(error as any);
        }
      );
      this._spinner.hide();
    }else{
      Swal.fire('Error', 'Debe capturar de forma correcta los datos requeridos', 'error');
    }

  }

  traer(){
    let alumno = {
      idAlumno: this.idAlumno
    };
    this._alumnoService.traerUno(alumno).subscribe(
      response => {
        console.log('Respuesta: ', response);
        if(response.success === 1){
          // Llenamos generales del alumno
          this.generales = response.data;
          this.alumno.controls.matricula.setValue(this.generales.matricula);
          this.alumno.controls.curp.setValue(this.generales.curp);
          this.alumno.controls.primerApellido.setValue(this.generales.primerApellido);
          this.alumno.controls.segundoApellido.setValue(this.generales.segundoApellido);
          this.alumno.controls.nombre.setValue(this.generales.nombre);
          this.alumno.controls.email.setValue(this.generales.email);
          this.alumno.controls.carrera.setValue(this.generales.idCarrera);

          this.antecendetes = response.antecedente;
          this.alumno.controls.procedencia.setValue(this.antecendetes.procedencia);
          this.alumno.controls.tipoEstudio.setValue(this.antecendetes.idTipoEstudio);
          this.alumno.controls.estado.setValue(this.antecendetes.claveEstado);
          this.alumno.controls.inicio.setValue(this.antecendetes.fechaInicio);
          this.alumno.controls.terminacion.setValue(this.antecendetes.fechaTerminacion);
          this.fecha = new Date(this.antecendetes.fechaTerminacion);
        }else{
          Swal.fire('Error', 'No existe Alumno a Mostrar', 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

  regresar(){
    this._router.navigateByUrl('dashboard/alumnos');
  }

  cancelar(){
    this._router.navigateByUrl('dashboard/alumno');
  }

  // Traer Auxiliares
  traeCarreras(){
    this._auxiliarService.carreras().subscribe(
      response => {
        if(response.success === 1){
          this.carreras = response.data;
        }else{
          Swal.fire('Error', response.error, 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

  traeEstudiosAntecedentes(){
    this._auxiliarService.estudiosAntecedentes().subscribe(
      response => {
        if(response.success === 1){
          this.tipoEstudiosAntecedentes = response.data;
        }else{
          Swal.fire('Error', response.error, 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

  traeEstados(){
    this._auxiliarService.estados().subscribe(
      response => {
        if(response.success === 1){
          this.estados = response.data;
        }else{
          Swal.fire('Error', response.error, 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }


}
