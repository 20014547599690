import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any[] = [
    {
      titulo: 'Catalogos',
      icono: 'fa fa-book',
      submenu: [
        { titulo: 'Usuarios', url: 'usuarios', icono: 'fa fa-id-badge' },
        // { titulo: 'Crud de Usuarios', url: 'userCrud', icono: 'fa fa-id-badge' }
        /* { titulo: 'Autorizacion/Recon.', url: '/', icono: 'fa fa-id-badge' },
        { titulo: 'Firmas', url: '/', icono: 'fa fa-id-badge' },
        { titulo: 'Carrera', url: '/', icono: 'fa fa-id-badge' },
        { titulo: 'Entidades', url: '/', icono: 'fa fa-id-badge' },
        { titulo: 'Estudios Antecedentes', url: '/', icono: 'fa fa-id-badge' },
        { titulo: 'Fundamentos Legales', url: '/', icono: 'fa fa-id-badge' },
        { titulo: 'Modalidad', url: '/', icono: 'fa fa-id-badge' } */
      ]

    },
    {
      titulo: 'Alumnos',
      icono: 'fa fa-graduation-cap',
      submenu: [
        { titulo: 'Nuevo', url: 'addAlumno', icono: 'fa fa-file-text-o' },
        { titulo: 'Catalogo', url: 'alumnos', icono: 'fa fa-address-book' }
      ]
    },
    {
      titulo: 'Titulos',
      icono: 'fa fa-certificate',
      submenu: [
        { titulo: 'Crear Titulo', url: 'titulo', icono: 'fa fa-flag-checkered' },
        { titulo: 'Firmar', url: 'firmar', icono: 'fa fa-exclamation' },
        { titulo: 'Gen. y Env.', url: 'generar', icono: 'fa fa-file-code-o' },
        { titulo: 'Ver Status', url: 'status', icono: 'fa fa-spinner' }
      ]
    }
  ];

  constructor() { }
}
