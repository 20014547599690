import { BotonInterface } from './../../interfaces/boton.interface';
import { CompartirService } from './../../services/compartir.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GLOBAL } from './../../services/global';
import { StatusService } from './../../services/status.service';
import { VariablesService } from './../../services/variables.service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styles: [
  ]
})
export class StatusComponent implements OnInit {

  public opciones: Array<{id: number, value: string}>=[
    { id:0, value: 'Todos' },
    { id:1, value: 'Curp' },
    { id:2, value: 'Lote' },
    { id:3, value: 'Folio' }
  ];

  public mostrarTabla=true;
  cabecera = [];
  origen=new MatTableDataSource();
  datos=[];

  private url = GLOBAL.url;

  public formBuscar: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _variablesService: VariablesService,
    private _statusService: StatusService,
    private _spinner: NgxSpinnerService,
    private _compartir: CompartirService
  ) {
    this.formBuscar = this._fb.group({
      idTipo: [0, Validators.required],
      curp: ['', null],
      lote: ['', null],
      folio: ['', null]
    });
  }

  ngOnInit(): void {
    this._compartir.idBotonObservable.subscribe(
      (boton: BotonInterface) => {
        if(boton.type === 6){
          this.status(boton.id);
        }
        if(boton.type === 7){
          this.cancelar(boton.id);
        }
        if(boton.type === 8){
          this.excel(boton.id);
        }
        if(boton.type === 9){
          this.descargaXml(boton.id);
        }
      });
  }

  async todos(formBuscar){
    if(formBuscar.valid){
      this._spinner.show();
      await this._statusService.todos(formBuscar.value).subscribe(
        response => {
          if(response.success === 1){
            this.cabecera=[];
            this.datos=[
              { titulo: 'Folio', fila: 'id', tam: '150px' },
              { titulo: 'Matricula', fila: 'matriculaAlumno', tam: '150px' },
              { titulo: 'CURP', fila: 'curpAlumno', tam: '200px' },
              { titulo: 'Nombre', fila: 'alumno', tam: '400px' },
              { titulo: 'Lote', fila: 'noLote', tam: '300px' }
            ];

            for(let col in this.datos){
              this.cabecera.push(this.datos[col].fila);
            }
            this.cabecera.push('status');
            this.origen.data = response.data;
            this.mostrarTabla = false;
          }else{
            Swal.fire('Error', response.error, 'error');
          }
        },
        error => {
          console.log(error as any);
        }
      );

      this._spinner.hide();
    }else{
      Swal.fire('Error', 'Datos Invalidos', 'error');
    }
  }

  async status(id){
    this._spinner.show();
    await this._statusService.verStatus(id).subscribe(
      response => {
        if(response.success === 1){
          if(response.estatusLote ===1 ){
            this.todos(this.formBuscar);
            Swal.fire('Exito', response.mensajeLote, 'success');

          }else{
            Swal.fire('Error', 'Ocurrio un error en el envio', 'error');
          }
        }else{
          Swal.fire('Error', 'Ocurrio un error en el envio', 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
    this._spinner.hide();

  }

  async excel(id){
    this._spinner.show();
    await this._statusService.descargar(id).subscribe(
      response => {
        if(response.success == 1){
          const linkSource = "data:application/pdf;base64," + response.titulo64;
          const downloadLink = document.createElement("a");
          const fileName = response.nombre;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }else{
          Swal.fire('Error', 'Archivo no econtrado', 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
    this._spinner.hide();
  }

  async descargaXml(id){
    console.log('aqui 2');
    this._spinner.show();

    await this._statusService.descargarXml(id).subscribe(
      response => {
        console.log(response);
        const linkSource = "data:application/pdf;base64," + response.data;
        const downloadLink = document.createElement("a");
        const fileName = response.lote+'.xml';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      },
      error => {
        console.log(error as any);
      }
    );

    this._spinner.hide();
  }

  async cancelar(id){
    var folio: String;
    folio = id.toString();
    if(folio.length == 1){
      folio='00000'+folio;
    }else if(folio.length == 2){
      folio='0000'+folio;
    }else if(id.length == 3){
      folio='000'+folio;
    }else if(folio.length == 4){
      folio='00'+folio;
    }else if(id.length == 5){
      folio='0'+folio;
    }

    Swal.fire({
      title: 'Cancelar Registro',
      text: 'Desea Cancelar el Registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {

        this._statusService.cancelar(folio, 'R3').subscribe(
          response => {
            if(response.success === 1){
              Swal.fire('Advertencia', response.mensaje, 'warning');
            }else{
              Swal.fire('Error', 'Error no reconocido', 'error');
            }
          },
          error => {
            console.log(error as any);
          }
        );

      }
    });
  }

}
