import { BotonInterface } from './../interfaces/boton.interface';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompartirService {

  public idBoton: BotonInterface;
  private idBotonSubject = new BehaviorSubject<BotonInterface>({
    type: 0,
    id: 0
  });

  idBotonObservable = this.idBotonSubject.asObservable();

  constructor() { }

  enviarBoton(idBoton: BotonInterface) {
    this.idBoton= idBoton;
    this.idBotonSubject.next(idBoton);
  }

}
