<!-- Footer -->
<footer class="site-footer">
    <div class="footer-inner bg-white">
        <div class="row">
            <div class="col-sm-6">
                Copyright &copy; {{ fecha }} Anglo Americano
            </div>
            <div class="col-sm-6 text-right">
                <a href="http://angloamericanogdl.edu.mx/">Anglo Americano </a>
            </div>
        </div>
    </div>
</footer>
<!-- /.site-footer -->
