import { GLOBAL } from './global';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlumnosService {

  private url: string;
  private token: string;

  constructor(
    private _httpClient: HttpClient
  ) { 
    this.url = GLOBAL.url;
  }

  todos(usuario): Observable<any>{

    let json=JSON.stringify(usuario);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'alumnos', params, { headers: headers });
  }

  nuevo(alumno): Observable<any>{
    let json=JSON.stringify(alumno);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'alumnos/add', params, { headers: headers });
  }

  modificar(alumno): Observable<any>{
    let json=JSON.stringify(alumno);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'alumnos/edit', params, { headers: headers });
  }

  borrar(alumno): Observable<any>{
    let json=JSON.stringify(alumno);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'alumnos/delete', params, { headers: headers });
  }

  titular(usuario): Observable<any>{

    let json=JSON.stringify(usuario);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'alumnos/titulacion', params, { headers: headers });
  }

  firma(usuario): Observable<any>{

    let json=JSON.stringify(usuario);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'alumnos/firmar', params, { headers: headers });
  }

  xml(usuario): Observable<any>{

    let json=JSON.stringify(usuario);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'alumnos/xml', params, { headers: headers });
  }

  traerUno(usuario): Observable<any>{

    let json=JSON.stringify(usuario);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   
    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'alumnos/view', params, { headers: headers });
  }

}
