import { LoginService } from './../services/login.service';
import { Injectable } from '@angular/core';
import { CanActivate, 
  CanActivateChild, 
  ActivatedRouteSnapshot, 
  RouterStateSnapshot, 
  UrlTree, 
  Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private _loginService: LoginService,
    private _router: Router
  ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): 
    Observable<boolean | UrlTree> | 
    Promise<boolean | UrlTree> | 
    boolean | 
    UrlTree {
      this._loginService.revisa().subscribe(
        response=>{
          if(response.success==1){
            if(response.valor==1){
              return true;
            }else{
              this._router.navigate(['/login']);
              return false;
            }
          }else{
            this._router.navigate(['/login']);
            return false;
          }
        },
        error=>{
          console.log(<any> error);
          this._router.navigate(['/login']);
          return false;
        }
      );
      
      return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): 
    Observable<boolean | UrlTree> | 
    Promise<boolean | UrlTree> | 
    boolean | 
    UrlTree {
      this._loginService.revisa().subscribe(
        response=>{
          if(response.success==1){
            if(response.valor==1){
              return true;
            }else{
              this._router.navigate(['/login']);
              return false;
            }
          }else{
            this._router.navigate(['/login']);
            return false;
          }
        },
        error=>{
          console.log(<any> error);
          this._router.navigate(['/login']);
          return false;
        }
      );
      
      return true;
  }
  
}
