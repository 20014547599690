import { GLOBAL } from './global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneraXmlService {

  public url: string;
  public token: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  genera(frm): Observable<any>{
    let json=JSON.stringify(frm);
    let params='json='+json;

    let token=JSON.parse(localStorage.getItem("ses"));
    this.token=(token==null)?'undefined':token.token;
   

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'xml/crear', params, { headers: headers });

  }

}
