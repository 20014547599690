import { BotonInterface } from './../../interfaces/boton.interface';
import { CompartirService } from './../../services/compartir.service';
import { VariablesService } from 'src/app/services/variables.service';
import { AlumnosService } from './../../services/alumnos.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alumnos',
  templateUrl: './alumnos.component.html',
  styleUrls: [
    './alumnos.component.css'
  ]
})
export class AlumnosComponent implements OnInit {

  public opciones: Array<{id: number, value: string}>=[
    { id:0, value: 'Todos' },
    { id:1, value: 'Curp' },
    { id:2, value: 'Matricula' },
    { id:3, value: 'Nombre' }
  ];

  public mostrarTabla=true;
  cabecera = [];
  origen=new MatTableDataSource();
  datos=[];

  public formBuscar: FormGroup;

  constructor(
    private _alumnosService: AlumnosService,
    private _fb: FormBuilder,
    private _router: Router,
    private _variablesService: VariablesService,
    private _compartir: CompartirService
  ) {
    this.formBuscar = this._fb.group({
      idTipo: [0, Validators.required],
      curp: ['', null],
      matricula: ['', null],
      apellido: ['', null],
      nombre: ['', null]
    });
  }

  ngOnInit(): void {
    this._compartir.idBotonObservable.subscribe(
      (boton: BotonInterface) => {
        if(boton.type === 1){
          this.editar(boton.id);
        }
        if(boton.type === 2){
          this.borrar(boton.id);
        }
      });
  }

  todos(formBuscar){
    if(formBuscar.valid){
      this._alumnosService.todos(formBuscar.value).subscribe(
        response => {
          if(response.success === 1){
            this.cabecera=[];
            this.datos=[
              { titulo: 'Matricula', fila: 'matricula', tam: '150px' },
              { titulo: 'CURP', fila: 'curp', tam: '200px' },
              { titulo: 'Nombre', fila: 'completo', tam: '400px' },
              { titulo: 'Carrera', fila: 'carrera', tam: '300px' }
            ];

            for(let col in this.datos){
              this.cabecera.push(this.datos[col].fila);
            }
            this.cabecera.push('actions');
            this.origen.data = response.data;
            this.mostrarTabla = false;
          }else{
            Swal.fire('Error', response.error, 'error');
          }
        },
        error => {
          console.log(error as any);
        }
      );
    }else{
      Swal.fire('Error', 'Datos Invalidos', 'error');
    }
  }

  agregar(){
    this._router.navigate(['/dashboard/addAlumno']);
  }

  editar(id){
    this._variablesService.idAlumno = id;
    this._router.navigateByUrl('dashboard/editAlumno');
  }

  borrar(id){
    Swal.fire({
      title: 'Esta Seguro',
      text: 'Desea Borrar la Informacion del Alumno?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        let alum={
          idAlumno: id
        }
        this._alumnosService.borrar(alum).subscribe(
          response => {
            if(response.success === 1){
              Swal.fire(
                'Borrado',
                'El registro ha sido Borrado.',
                'success'
              );
            }else{
              Swal.fire(
                'Error',
                'No se ha podido borrar el registro.',
                'error'
              );
            }
          },
          error => {
            console.log(error as any);
          }
        );
      }
    });
  }

}
