import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosService } from './../../services/usuarios.service';
import { catCargosI } from './../../interfaces/catCargosI';
import { AuxiliarService } from 'src/app/services/auxiliar.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuario-nuevo',
  templateUrl: './usuario-nuevo.component.html'
})
export class UsuarioNuevoComponent implements OnInit {

  public formUsuario: FormGroup;
  public cargosA: Array<catCargosI>;
  public cer: File;
  public key: File;

  constructor(
    private _fb: FormBuilder,
    private _auxiliarService: AuxiliarService,
    private _usuariosService: UsuariosService,
    private _router: Router,
    private _spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.formUsuario = this._fb.group({
      email: ['', [Validators.email, Validators.required]],
      primerApellido: ['', [Validators.required]],
      segundoApellido: ['', [Validators.required]],
      nombre: ['', [Validators.required]],
      fechaExpira: ['', null],
      idPerfil: [1, [Validators.min(1)]],
      idInstitucion: [1, [Validators.min(1)]],
      firmar: [false, [Validators.min(1)]],
      abrebiatura: ['', [Validators.min(1)]],
      curp: ['', [Validators.pattern('^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$')]],
      idCargo: ['', [Validators.min(1)]],
    });

    this.cargos();
  }

  async guardar(form){
    if(form.valid){
      this._spinner.show();
      // Si vienen imagenes
      if(form.value.firmar){
        await this._usuariosService.nuevoUsrImg(this.cer, this.key, form.value)
          .then(
            resultado => {
              if(resultado.success === 1 ){
                Swal.fire('Correcto', 'El usuario se ha generado con exito.', 'success');
                this._router.navigateByUrl('dashboard/usuarios');
              }else{
                Swal.fire('Error', resultado.msg, 'error');
              }
            }
          );
      }else if(!form.value.firmar){
        await this._usuariosService.nuevoUsr(form.value).subscribe(
          (response: any) => {
            if(response.success === 1 ){
              Swal.fire('Correcto', 'El usuario se ha generado con exito.', 'success');
              this._router.navigateByUrl('dashboard/usuarios');
            }else{
              Swal.fire('Error', response.msg, 'error');
            }
          },
          error => {
            console.log(error as any);
          }
        );
      }else{
        Swal.fire('Error', 'No se ha enviado informacion', 'error');
      }
      this._spinner.hide();
    }
  }

  cancelar(){}

  cambio(){
    // Habilitar las Validaciones
    if(this.formUsuario.value.firmar){
      this.formUsuario.controls['curp'].setValidators([Validators.required, Validators.pattern('^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$')]);
      this.formUsuario.controls['idCargo'].setValidators([Validators.required]);
      this.formUsuario.controls['idCargo'].updateValueAndValidity();
      this.formUsuario.controls['curp'].updateValueAndValidity();
    }else{
      this.formUsuario.controls['curp'].clearValidators();
      this.formUsuario.controls['idCargo'].clearValidators();
      this.formUsuario.controls['curp'].setValidators([Validators.pattern('^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$')]);
      this.formUsuario.controls['idCargo'].setValidators([Validators.min(1)]);
      this.formUsuario.controls['idCargo'].updateValueAndValidity();
      this.formUsuario.controls['curp'].updateValueAndValidity();
    }
  }

  archivoCer(file: File){
    this.cer = file;
  }

  archivoKey(file: File){
    this.key = file;
  }

  // Auxiliares
  cargos(){
    this._auxiliarService.cargos().subscribe(
      response => {
        if(response.success ===1 ){
          this.cargosA = response.data;
        }else{
          Swal.fire('Error', 'No existen datos', 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

}
