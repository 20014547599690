<div class="orders">
    <div class="row">
        <div class="col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="box-title">Alta de Titulos </h4>
                </div>
                <div class="card-body-- p-2">
                    <form [formGroup]="formBuscar" (ngSubmit)="todos(formBuscar)">
                        <div class="filtro mb-4 row">
                            <div class="col-3">
                                <select formControlName="idTipo" class="form-control">
                                    <option *ngFor="let opcion of opciones" value="{{ opcion.id }}">{{ opcion.value }}</option>
                                </select>
                            </div>
                            <div class="col-7">
                                <div *ngIf="formBuscar.value.idTipo==1" class="form-group row">

                                    <div class="col-12">
                                        <input type="text" formControlName="curp" name="curp" id="curp" class="form-control" placeholder="CURP">
                                    </div>
                                </div>

                                <div *ngIf="formBuscar.value.idTipo==2" class="form-group row">

                                    <div class="col-12">
                                        <input type="text" formControlName="matricula" name="matricula" id="matricula" class="form-control" placeholder="Matricula">
                                    </div>
                                </div>

                                <div *ngIf="formBuscar.value.idTipo==3" class="form-group row">

                                    <div class="col-6">
                                        <input type="text" formControlName="apellido" name="apellido" id="apellido" class="form-control" placeholder="Primer Apellido">
                                    </div>
                                    <div class="col-6">
                                        <input type="text" formControlName="nombre" name="nombre" id="nombre" class="form-control" placeholder="Nombre">
                                    </div>
                                </div>

                            </div>
                            <div class="col-2 text-right">
                                <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>&nbsp;
                            </div>
                        </div>
                    </form>
                    <div class="row">
                        <div class="col-xl-12">
                            <app-tabla [columnas]="datos"
                                [displayedColumns]="cabecera"
                                [dataSource]="origen"
                                [hidden]="mostrarTabla"
                                >
                            </app-tabla>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

