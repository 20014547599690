<!-- Left Panel -->
<aside id="left-panel" class="left-panel">
    <nav class="navbar navbar-expand-sm navbar-default">
        <div id="main-menu" class="main-menu collapse navbar-collapse">
            <ul class="nav navbar-nav">

                <li class="menu-title">Dashboard</li><!-- /.menu-title -->


                <li *ngFor="let item of menuItems" class="menu-item-has-children dropdown">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="menu-icon {{ item.icono }}"></i>{{ item.titulo }}</a>
                    <ul class="sub-menu children dropdown-menu">
                        <li *ngFor="let submenu of item.submenu"><i class="{{ submenu.icono }}"></i><a [routerLink]="submenu.url" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">{{ submenu.titulo }}</a></li>
                    </ul>
                </li>

            </ul>
        </div><!-- /.navbar-collapse -->
    </nav>
</aside>
<!-- /#left-panel -->
