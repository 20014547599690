<div class="col-lg-12">
    <div class="card">
        <div class="card-header">
            <h4>Modificar Alumnos</h4>
        </div>
        <div class="card-body">
            <form [formGroup]="alumno" (ngSubmit)="modificar(alumno)" class="form-horizontal">
                <ul class="nav nav-tabs"
                    id="myTab"
                    role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active"
                            id="generales-tab"
                            data-toggle="tab"
                            href="#generales"
                            role="tab"
                            aria-controls="generales"
                            aria-selected="true">Generales.
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                        id="antecedentes-tab"
                        data-toggle="tab"
                        href="#antecedentes"
                        role="tab"
                        aria-controls="antecedentes"
                        aria-selected="false">Antecedentes.
                        </a>
                    </li>

                </ul>

                <br>
                <div class="tab-content pl-3 p-1" id="myTabContent">
                    <div class="tab-pane fade show active" id="generales" role="tabpanel" aria-labelledby="generales-tab">
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="text-input" class=" form-control-label">Matricula (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text"
                                        id="text-input"
                                        #matricula (keyup)="alumno.patchValue({matricula: $event.target.value.toUpperCase()})"
                                        formControlName="matricula"
                                        name="text-input"
                                        placeholder="Matricula"
                                        readonly
                                        class="form-control">
                                <small *ngIf="alumno.controls['matricula'].invalid && (alumno.controls['matricula'].dirty || alumno.controls['matricula'].touched)" class="form-text text-danger">Error en Matricula</small>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="text-input" class=" form-control-label">CURP (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text"
                                        id="text-input"
                                        #curp (keyup)="alumno.patchValue({curp: $event.target.value.toUpperCase()})"
                                        formControlName="curp"
                                        name="text-input"
                                        placeholder="CURP"
                                        class="form-control">
                                <small *ngIf="alumno.controls['curp'].invalid && (alumno.controls['curp'].dirty || alumno.controls['curp'].touched)" class="form-text text-danger">Error en CURP</small>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="text-input" class=" form-control-label">Primer Apellido (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text"
                                        id="text-input"
                                        #primerApellido (keyup)="alumno.patchValue({primerApellido: $event.target.value.toUpperCase()})"
                                        formControlName="primerApellido"
                                        name="text-input"
                                        placeholder="Primer Apellido"
                                        class="form-control">
                                <small *ngIf="alumno.controls['primerApellido'].invalid && (alumno.controls['primerApellido'].dirty || alumno.controls['primerApellido'].touched)" class="form-text text-danger">Error en Primer Apellido</small>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="text-input" class=" form-control-label">Segundo Apelldio</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text"
                                        id="text-input"
                                        #segundoApellido (keyup)="alumno.patchValue({segundoApellido: $event.target.value.toUpperCase()})"
                                        formControlName="segundoApellido"
                                        name="text-input"
                                        placeholder="Segundo Apellido"
                                        class="form-control">
                                <small *ngIf="alumno.controls['segundoApellido'].invalid && (alumno.controls['segundoApellido'].dirty || alumno.controls['segundoApellido'].touched)" class="form-text text-danger">Error en Segundo Apellido</small>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="text-input" class=" form-control-label">Nombre (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text"
                                        id="text-input"
                                        formControlName="nombre"
                                        name="text-input"
                                        #nombre (keyup)="alumno.patchValue({nombre: $event.target.value.toUpperCase()})"
                                        placeholder="Nombre (s)"
                                        class="form-control">
                                <small *ngIf="alumno.controls['nombre'].invalid && (alumno.controls['nombre'].dirty || alumno.controls['nombre'].touched)" class="form-text text-danger">Error en Nombre</small>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="text-input" class=" form-control-label">Correo Electronico (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="email"
                                        id="text-input"
                                        formControlName="email"
                                        name="text-input"
                                        placeholder="Correo Electronico"
                                        class="form-control">
                                <small *ngIf="alumno.controls['email'].invalid && (alumno.controls['email'].dirty || alumno.controls['email'].touched)" class="form-text text-danger">Error en Correo Electronico</small>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="select" class=" form-control-label">Carrera (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <select name="carrera" id="carrera" formControlName="carrera" class="form-control">
                                    <option *ngFor="let carrera of carreras" [value]="carrera.id" [selected]="carrera.id === alumno.value.carrera">{{ carrera.nombre }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="antecedentes" role="tabpanel" aria-labelledby="antecedentes-tab">
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="text-input" class=" form-control-label">Procedencia (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <input type="text"
                                        id="text-input"
                                        #procedencia (keyup)="alumno.patchValue({procedencia: $event.target.value.toUpperCase()})"
                                        formControlName="procedencia"
                                        name="text-input"
                                        placeholder="Procedencia"
                                        class="form-control">
                                <small *ngIf="alumno.controls['procedencia'].invalid && (alumno.controls['procedencia'].dirty || alumno.controls['procedencia'].touched)" class="form-text text-danger">Error en Procedencia</small>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="select" class=" form-control-label">Tipo de Estudio (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <select name="select" id="select" formControlName="tipoEstudio" class="form-control">
                                    <option *ngFor="let tipoEst of tipoEstudiosAntecedentes" [value]="tipoEst.id" [selected]="tipoEst.id === alumno.value.tipoEstudio">{{ tipoEst.tipoEstudio }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="select" class=" form-control-label">Estado (*)</label>
                            </div>
                            <div class="col-12 col-md-9">
                                <select name="select" id="select" formControlName="estado" class="form-control">
                                    <option *ngFor="let estado of estados" [value]="estado.id" [selected]="estado.id === alumno.value.estado">{{ estado.nombreEntidad }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col col-md-3">
                                <label for="text-input" class=" form-control-label">fecha de Terminacion</label>
                            </div>
                            <div class="col-12 col-md-3">
                                <input type="date"
                                    id="text-input"
                                    #terminacion (keyup)="alumno.patchValue({terminacion: $event.target.value.toUpperCase()})"
                                    formControlName="terminacion"
                                    name="text-input"
                                    placeholder="Fecha Terminacion"
                                    value="{{ fecha | date:'yyyy-MM-dd' }}"
                                    max="{{ fecha | date:'y-MM-dd' }}"
                                    min="{{ alumno.value.inicio | date:'y-MM-dd' }}"
                                    class="form-control">
                                <small *ngIf="alumno.controls['terminacion'].invalid && (alumno.controls['terminacion'].dirty || alumno.controls['terminacion'].touched)" class="form-text text-danger">Error en fecha de terminacion</small>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="text-muted m-b-15"><b>NOTA:</b> Los Campos con (*) son Requeridos.</p>
                <div class="col-12 text-right">
                    <button type="button" (click)="cancelar()" class="btn btn-danger">Cancelar</button>&nbsp;&nbsp;
                    <button type="submit" [disabled]="alumno.invalid" class="btn btn-success">Guardar</button>
                </div>
            </form>
        </div>
    </div>
</div>
