import { AlumnosService } from './../../services/alumnos.service';
import { catEstadosI } from './../../interfaces/catEstadosI';
import { estudiosAntecedentesI } from './../../interfaces/estudiosAntecedentesI';
import { AuxiliarService } from './../../services/auxiliar.service';
import { catCarrerasI } from './../../interfaces/catCarrerasI';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-alumno',
  templateUrl: './add-alumno.component.html'
})
export class AddAlumnoComponent implements OnInit {

  public alumno: FormGroup;
  public carreras: Array<catCarrerasI>;
  public tipoEstudiosAntecedentes: Array<estudiosAntecedentesI>;
  public estados: Array<catEstadosI>;

  fecha=new Date();

  public loading = false;

  constructor(
    private _fb: FormBuilder,
    private _auxiliarService: AuxiliarService,
    private _alumnoService: AlumnosService,
    private _spinner: NgxSpinnerService
  ) {
    this.alumno = this._fb.group({
      idAlumno: [0, Validators.required],
      matricula: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]],
      curp: ['', [Validators.required, Validators.pattern('^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$')]],
      primerApellido: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(500)]],
      segundoApellido: ['', Validators.maxLength(500)],
      nombre: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(500)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(500)]],
      carrera: [0, [Validators.required, Validators.pattern('[1-9]')]],
      procedencia: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],
      tipoEstudio: [0, [Validators.required, Validators.pattern('[1-9]')]],
      estado: [0, [Validators.required]],
      inicio: ['', null],
      terminacion: ['', [Validators.required]]
    });
   }

  ngOnInit(): void {
    this._spinner.show();
    this.traeCarreras();
    this.traeEstudiosAntecedentes();
    this.traeEstados();
    this._spinner.hide();
  }

  async agregar(formulario){
    if(formulario.valid){
      this._spinner.show();
      //formulario.value.inicio = formulario.value.inicio+'-01';
      await this._alumnoService.nuevo(formulario.value).subscribe(
        response => {
          console.log(response);
          if(response.success === 1){
            Swal.fire('Exito', 'Alumno Guardado con Exito', 'success');
            formulario.reset();
          }else{
            Swal.fire('Error', response.error, 'error');
          }
        },
        error => {
          console.log(error as any);
        }
      );
      this._spinner.hide();
    }else{
      Swal.fire('Error', 'Debe capturar de forma correcta los datos requeridos', 'error');
    }
    
  }

  // Traer Auxiliares
  traeCarreras(){
    this._auxiliarService.carreras().subscribe(
      response => {
        if(response.success === 1){
          this.carreras = response.data;
        }else{
          Swal.fire('Error', response.error, 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

  traeEstudiosAntecedentes(){
    this._auxiliarService.estudiosAntecedentes().subscribe(
      response => {
        if(response.success === 1){
          this.tipoEstudiosAntecedentes = response.data;
        }else{
          Swal.fire('Error', response.error, 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

  traeEstados(){
    this._auxiliarService.estados().subscribe(
      response => {
        if(response.success === 1){
          this.estados = response.data;
        }else{
          Swal.fire('Error', response.error, 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
  }

}
