<ng-template #customLoadingTemplate>
    <div style="background: #eee; height: 100%">
        my block
    </div>
</ng-template>

<ngx-loading 
    [show]="loading" 
    [config]="{ backdropBorderRadius: '3px' }" 
    [template]="customLoadingTemplate">
</ngx-loading>


