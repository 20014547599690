import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule } from '@angular/flex-layout';



import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AlumnosComponent } from './alumnos/alumnos.component';
import { TablaComponent } from './compartido/tabla/tabla.component';
import { AddAlumnoComponent } from './add-alumno/add-alumno.component';
import { FirmarComponent } from './firmar/firmar.component';
import { GenerarXmlComponent } from './generar-xml/generar-xml.component';
import { TituloComponent } from './titulo/titulo.component';
import { NuevoTituloComponent } from './nuevo-titulo/nuevo-titulo.component';
import { EditAlumnoComponent } from './edit-alumno/edit-alumno.component';
import { FirmarNuevoComponent } from './firmar-nuevo/firmar-nuevo.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { UsuarioNuevoComponent } from './usuario-nuevo/usuario-nuevo.component';
import { StatusComponent } from './status/status.component';




@NgModule({
  declarations: [
    PagesComponent,
    DashboardComponent,
    AlumnosComponent,
    TablaComponent,
    AddAlumnoComponent,
    FirmarComponent,
    GenerarXmlComponent,
    TituloComponent,
    NuevoTituloComponent,
    EditAlumnoComponent,
    FirmarNuevoComponent,
    UsuariosComponent,
    UsuarioNuevoComponent,
    StatusComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
  ],
  exports: [
    PagesComponent
  ],
  providers:[
    DatePipe,
    DecimalPipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PagesModule { }
