import { BotonInterface } from './../../interfaces/boton.interface';
import { CompartirService } from './../../services/compartir.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneraXmlService } from './../../services/genera-xml.service';
import { GLOBAL } from './../../services/global';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlumnosService } from 'src/app/services/alumnos.service';
import { VariablesService } from 'src/app/services/variables.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-generar-xml',
  templateUrl: './generar-xml.component.html'
})
export class GenerarXmlComponent implements OnInit {

  public opciones: Array<{id: number, value: string}>=[
    { id:0, value: 'Todos' },
    { id:1, value: 'Curp' },
    { id:2, value: 'Matricula' },
    { id:3, value: 'Nombre' }
  ];

  public mostrarTabla=true;
  cabecera = [];
  origen=new MatTableDataSource();
  datos=[];

  url: string;

  public formBuscar: FormGroup;

  constructor(
    private _alumnosService: AlumnosService,
    private _fb: FormBuilder,
    private _variablesService: VariablesService,
    private _router: Router,
    private _generaXmlService: GeneraXmlService,
    private _spinner: NgxSpinnerService,
    private _compartir: CompartirService
  ) {
    this.formBuscar = this._fb.group({
      idTipo: [0, Validators.required],
      curp: ['', null],
      matricula: ['', null],
      apellido: ['', null],
      nombre: ['', null]
    });
    this.url=GLOBAL.url;
   }

  ngOnInit(): void {
    this._compartir.idBotonObservable.subscribe(
      (boton: BotonInterface) => {
        if(boton.type === 5){
          this.xml(boton.id);
        }
      });
  }

  async todos(formBuscar){
    if(formBuscar.valid){
      this._spinner.show();
      await this._alumnosService.xml(formBuscar.value).subscribe(
        response => {
          if(response.success === 1){
            this.cabecera=[];
            this.datos=[
              { titulo: 'Matricula', fila: 'matricula', tam: '150px' },
              { titulo: 'CURP', fila: 'curp', tam: '200px' },
              { titulo: 'Nombre', fila: 'completo', tam: '400px' },
              { titulo: 'Carrera', fila: 'carrera', tam: '300px' }
            ];

            for(let col in this.datos){
              this.cabecera.push(this.datos[col].fila);
            }
            this.cabecera.push('xml');

            this.origen.data = response.data;
            this.mostrarTabla = false;
          }else{
            // Swal.fire('Error', response.error, 'error');
            this.mostrarTabla = true;
          }
        },
        error => {
          console.log(error as any);
        }
      );
      this._spinner.hide();
    }else{
      Swal.fire('Error', 'Datos Invalidos', 'error');
    }
  }

  async xml(emiter: number){
    this._variablesService.idAlumno=emiter;

    let envio = {
      idAlumno: this._variablesService.idAlumno
    };

    this._spinner.show();
    await this._generaXmlService.genera(envio).subscribe(
      response => {
        if(response.success === 1){
          this.todos(this.formBuscar);
          Swal.fire('Envio Correcto', 'XMl Generado y enviado correctamente con número de lote: <b>'+response.data+'</b> <br>Favor de Guardarlo.', 'success');
        }else{
          Swal.fire('Error', 'Error no reconocido, favor de revisar los datos enviados', 'error');
        }
      },
      error => {
        console.log(error as any);
      }
    );
    this._spinner.hide();

  }

  cancelar(){
    this._router.navigateByUrl('dashboard');
  }

}
