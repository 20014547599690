import { GLOBAL } from './global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public url: string;
  public token: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  acceso(usuario): Observable<any>{
    let json=JSON.stringify(usuario);
    let params='json='+json;

    let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
                                  //.append('Access-Control-Allow-Origin', '*');
                                 //.set('Authorization', this.getToken());
    
     return this._httpClient.post(this.url+'login', params, { headers: headers });

  }

  revisa(): Observable<any>{
   let token=JSON.parse(localStorage.getItem("ses"));
   this.token=(token==null)?'undefined':token.token;
   /*if(this.token!='undefined'){
     let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._http.post(this.url+'user/revisa', null, { headers: headers });
   }*/

   let headers=new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                 .set('Authorization', this.token);

     return this._httpClient.post(this.url+'login/revisa', null, { headers: headers });

  }
}
