import { BotonInterface } from './../../interfaces/boton.interface';
import { CompartirService } from './../../services/compartir.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { VariablesService } from 'src/app/services/variables.service';
import { AlumnosService } from 'src/app/services/alumnos.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-firmar',
  templateUrl: './firmar.component.html'
})
export class FirmarComponent implements OnInit {

  public opciones: Array<{id: number, value: string}>=[
    { id:0, value: 'Todos' },
    { id:1, value: 'Curp' },
    { id:2, value: 'Matricula' },
    { id:3, value: 'Nombre' }
  ];

  public mostrarTabla=true;
  cabecera = [];
  origen=new MatTableDataSource();
  datos=[];

  public formBuscar: FormGroup;

  constructor(
    private _alumnosService: AlumnosService,
    private _fb: FormBuilder,
    private _variablesService: VariablesService,
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private _compartir: CompartirService
  ) {
    this.formBuscar = this._fb.group({
      idTipo: [0, Validators.required],
      curp: ['', null],
      matricula: ['', null],
      apellido: ['', null],
      nombre: ['', null]
    });
   }

  ngOnInit(): void {
    this._compartir.idBotonObservable.subscribe(
      (boton: BotonInterface) => {
        if(boton.type === 4){
          this.firmar(boton.id);
        }
      });
  }

  todos(formBuscar){
    if(formBuscar.valid){
      this._spinner.show();
      this._alumnosService.firma(formBuscar.value).subscribe(
        response => {
          if(response.success === 1){
            this.cabecera=[];
            this.datos=[
              { titulo: 'Matricula', fila: 'matricula', tam: '150px' },
              { titulo: 'CURP', fila: 'curp', tam: '200px' },
              { titulo: 'Nombre', fila: 'completo', tam: '400px' },
              { titulo: 'Carrera', fila: 'carrera', tam: '300px' }
            ];

            for(let col in this.datos){
              this.cabecera.push(this.datos[col].fila);
            }
            this.cabecera.push('firmar');

            this.origen.data = response.data;
            this.mostrarTabla = false;
          }else{
            Swal.fire('Error', response.error, 'error');
          }
        },
        error => {
          console.log(error as any);
        }
      );
      this._spinner.hide();
    }else{
      Swal.fire('Error', 'Datos Invalidos', 'error');
    }
  }

  firmar(emiter: number){
    this._variablesService.idAlumno=emiter;

    this._router.navigateByUrl('dashboard/firmar-nuevo');
  }

  cancelar(){
    this._router.navigateByUrl('dashboard/titulo');
  }

}
