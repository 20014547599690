import { LoginService } from './../../services/login.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swall from 'sweetalert2';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [
  ]
})
export class LoginComponent implements OnInit {

  //private linkTheme = document.querySelector('body');
  public loginAcceso: FormGroup;

  constructor(
    private _loginService: LoginService,
    private _fb: FormBuilder,
    private _router: Router
  ) {
    //this.linkTheme.setAttribute('class', 'bg-dark')
    this.loginAcceso=this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      pass: ['', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      getToken: ['false', Validators.required]
    });
   }

  ngOnInit(): void {
  }

  acceso(formulario){
    if(formulario.valid){
      this._loginService.acceso(formulario.value).subscribe(
        response => {
          if(response.success === 1){
            localStorage.setItem('ses', JSON.stringify(response));
            this._router.navigate(['/dashboard']);
          }else{
            Swal.fire('Error', 'Usuario o contraseña incorrectos.', 'error');
          }
        },
        error => {
          console.log(error as any);
        }
      );
    }else{
      Swall.fire('Error: ', 'Debe Capturar de Forma correcta los datos Requeridos', 'error');
    }
  }

}
