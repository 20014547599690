<div class="sufee-login d-flex align-content-center flex-wrap">
    <div class="container">
        <div class="login-content">
            <div class="login-logo">
                <a href="index.html">
                    <img style="width: 50%;" class="align-content" src="./assets/images/logoA.png" alt="">
                </a>
            </div>
            <div class="login-form">
                <form [formGroup]="loginAcceso" (ngSubmit)="acceso(loginAcceso)">
                    <div class="form-group">
                        <label>Correo</label>
                        <input type="email" formControlName="email" class="form-control" placeholder="Correo">
                        
                        <div *ngIf="loginAcceso.controls['email'].invalid && (loginAcceso.controls['email'].dirty || loginAcceso.controls['email'].touched)" class="alert alert-danger" role="alert">
                            <div *ngIf="loginAcceso.controls['email'].errors.required">
                                El Email es Requerido.
                            </div>
                            <div *ngIf="loginAcceso.controls['email'].errors.pattern">
                                El Email esta mal formado.
                            </div>
                            <div *ngIf="loginAcceso.controls['email'].errors.email">
                                El Email esta mal formado.
                            </div>
                        </div>

                    </div>
                    <div class="form-group">
                        <label>Contraseña</label>
                        <input type="password" formControlName="pass" class="form-control" placeholder="Contraseña">

                        <div *ngIf="loginAcceso.controls['pass'].invalid && (loginAcceso.controls['pass'].dirty || loginAcceso.controls['pass'].touched)" class="alert alert-danger" role="alert">
                            <div *ngIf="loginAcceso.controls['pass'].errors.required">
                                La Contraseña es Requerida.
                            </div>
                            <div *ngIf="loginAcceso.controls['pass'].errors.pattern">
                                La contraseña esta mal formada (1 Maysucula, 1 Minuscula, 1 Digito, 8-30 Caracteres).
                            </div>
                        </div>

                    </div>

                    <button type="submit" class="btn btn-success btn-flat m-b-30 m-t-30">Entrar</button>

                </form>
            </div>
        </div>
    </div>
</div>
