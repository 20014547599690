import { NgxSpinnerService } from 'ngx-spinner';
import { TitulosService } from './../../services/titulos.service';
import { VariablesService } from './../../services/variables.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-firmar-nuevo',
  templateUrl: './firmar-nuevo.component.html'
})
export class FirmarNuevoComponent implements OnInit {

  idAlumno: number;
  cadenaCop: string;

  public firmar: FormGroup;


  constructor(
    private _variablesService: VariablesService,
    private _router: Router,
    private _titulosService: TitulosService,
    private _fb: FormBuilder,
    private _spinner: NgxSpinnerService
  ) {
    this.idAlumno=this._variablesService.idAlumno;
    if( typeof this.idAlumno=='undefined'){
      Swal.fire('Error', 'No se ha seleccionado Alumno', 'error');
      this._router.navigateByUrl('dashboard/firmar');
    }
   }

  ngOnInit(): void {    
    this.firmar=this._fb.group({
      passCert: ['', [Validators.required]],
      idAlumno: [this.idAlumno, [Validators.required]]
    });
  }

  async guardar(frm){
    if(frm.valid){
      this._spinner.show();
      await this._titulosService.guardaFirma(frm.value).subscribe(
        response => {
          if(response.success === 1){
            Swal.fire('Exito', 'Titulo Firmado con Exito', 'success');
            frm.reset();
            this._router.navigateByUrl('dashboard/firmar');
          }else{
            Swal.fire('Error', 'Algo Salio Mal, Intente de nuevo', 'error');
          }
        },
        error => {
          console.log(error as any);
        }
      );
      this._spinner.hide();
    }else{
      Swal.fire('Error', 'Favor de llenar los datos requeridos.', 'error');
    }
  }

  cancelar(){
    this._router.navigateByUrl('dashboard/firmar');
  }

}
